import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PVD",
  "description": "For producing thin films on metals, which can alter their chemical, mechanical, optical and electrical properties.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Powder-Metallurgy", "Powder-Coating"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`PVD or Physical Vapour Deposition is a procedure of applying a thin layer of coating to metal by vaporizing a coating material in a vacuum chamber, before depositing the vapor to the metal object. `}<strong parentName="p">{`Chemical, mechanical, optical,`}</strong>{` and `}<strong parentName="p">{`electrical`}</strong>{` characteristics of the metal will be altered according to the choice of coating used in the PVD process. Here in NexPCB, we commonly use aluminum powder as the base metal, which will give the workpiece a silvery-white finish. `}</p>
    <p>{`Once applied, PVD coatings must first be UV treated since they fall off easily. After that, they are almost impossible to remove, and will not wear off on their own. PVD coating is commonly used in aerospace, medical, electronics, and industrial applications to increase the mechanical and electrical strength of the product and prevent corrosion. `}</p>
    <p>{`There are 3 main types of PVD coating mechanisms:`}</p>
    <ol>
      <li parentName="ol">{`Thermal Evaporation`}</li>
    </ol>
    <p>{`Thermal evaporation is commonly applied to coating metals, such as Copper, Aluminum, and Chromium, for applications in computer industries as film packagings. `}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Sputter Deposition`}</li>
    </ol>
    <p>{`The sputter deposition technique uses the ionization technique by applying a high electric or magnetic field to the said material to be vaporized. The process is commonly used in medical industries for lab products and optical films.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Arc Vapor Deposition`}</li>
    </ol>
    <p>{`The process is done by first evaporating the coating material, before having it combine with reactive gas molecules in the plasma state before depositing. This method allows the creation of coatings of materials in hues (i.e. non-monochromatic or in hues).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      